import React from "react";
import "../TopHeaderComponent/TopHeader.css";
const TopHeader = () => {
  return (
    <div className="topheader-section">
      <div className="topheader-title">
        <h6>PROGRAM STARTS MAY 15 ! </h6>
      </div>
    </div>
  );
};

export default TopHeader;
