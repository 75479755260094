import React from "react";
import { Helmet } from "react-helmet";
import "../ThanksComponent/ThanksComponent.css";
import Logo from "../../assets/images/logo-footer.svg";

const ThanksComponent = () => {
  return (
    <div className="thank-you">
      <Helmet>
        {/* Google Tag Manager */}
        <script>
          {`
            (function(w,d,s,l,i){
              w[l]=w[l]||[];
              w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
              var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;
              j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-N4F7HPFS');
          `}
        </script>
        {/* End Google Tag Manager */}
      </Helmet>
      <div className="logo">
        <img src={Logo} alt="" />
      </div>

      <div className="content-thanks">
        <div>
          <h1>Your application is submitted successfully. </h1>
          <h3>
            Due to the large number of scholarship applications, it may take up
            to 48 hours for your application to be reviewed.
          </h3>
          <p>
            If you wish to expedite your request,
            <span>please book a meeting</span> with our Scholarship Specialist
            using the link below.
          </p>
        </div>
        <a
          href="https://calendly.com/jeff-zwhl/creativehub-30min?month=2024-04"
          target="_blank">
          <button>Book a Call</button>
        </a>
      </div>

      {/* Facebook Pixel */}
      <script>
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '371162252294230');
          fbq('track', 'PageView');
        `}
      </script>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=371162252294230&ev=PageView&noscript=1"
        />
      </noscript>
      {/* End Facebook Pixel */}
    </div>
  );
};

export default ThanksComponent;
